<template>
    <a-drawer v-model:visible="visible" :closable="false" @close="handleCancel" title="质检组详情">
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-tabs v-model:value="activeTabKey">
            <a-tab-pane :key="1" tab="基础信息">
                <a-descriptions title="" :column="2">
                    <a-descriptions-item label="物料质检组编码">
                        {{ baseInfo?.code }}
                    </a-descriptions-item>
                    <a-descriptions-item label="物料质检组名称">
                        {{ baseInfo?.name }}
                    </a-descriptions-item>
                    <a-descriptions-item label="质检方案">
                        {{ baseInfo?.basQcPlan?.name }}
                    </a-descriptions-item>
                    <a-descriptions-item label="方案编码">
                        {{ baseInfo?.basQcPlan?.code }}
                    </a-descriptions-item>
                </a-descriptions>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="质检方案信息">
                <PlanInfo :planInfo="planInfo" />
            </a-tab-pane>
            <a-tab-pane :key="3" tab="物料列表">
                <MaterialList :list="materialList" :allowOperate="false" />
            </a-tab-pane>
            <a-tab-pane :key="4" tab="操作记录">
                <OperateRecord :data="operateData" />
            </a-tab-pane>
        </a-tabs>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiMaterialCheckTeamDetail } from "@/api/qualityCheck";
import { getStatusColor } from "@/utils/common";
import OperateRecord from "./operateRecord";
import PlanInfo from "./planInfo";
import MaterialList from "../materialList";

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId"],
    components: {
        OperateRecord,
        PlanInfo,
        MaterialList,
    },
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            visible: false,
            tableData: [],
            activeTabKey: 1,
            baseInfo: null,
            planInfo: null,
            materialList: [],
            operateData: [],
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getDetailData = async id => {
            let res = await apiMaterialCheckTeamDetail(id);
            if (res.status === "SUCCESS") {
                const { basQcPlan, materials, operates } = res?.data;
                state.baseInfo = res.data;
                state.planInfo = basQcPlan;
                state.materialList = materials;
                state.operateData = operates;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            getStatusColor,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
