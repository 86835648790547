<template>
    <div class="container">
        <UpDateModal v-model:upDateId="upDateId" @onUpdateSuccess="getTabelData" />
        <DetailDrawer v-model:detailId="detailId" />
        <FilterBlock @search="search" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1200 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'planCode'">
                        {{ record?.basQcPlan?.code }}
                    </template>
                    <template v-if="column.dataIndex === 'planName'">
                        {{ record?.basQcPlan?.name }}
                    </template>
                    <template v-if="column.dataIndex === 'enabled'">
                        <a-switch
                            :checked="record.enabled"
                            @change="enabled => changeSwitch(record.enabled, record.id)"
                        />
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => (detailId = record.id)">详情</a>
                            <a
                                v-perm="'btn:material-qc-group-mgr-edit'"
                                @click="() => (upDateId = record.id)"
                            >
                                编辑
                            </a>
                            <a
                                v-perm="'btn:material-qc-group-mgr-delete'"
                                @click="handleDelete(record.id)"
                            >
                                删除
                            </a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button
                            v-perm="'btn:material-qc-group-mgr-add'"
                            type="primary"
                            @click="() => (upDateId = '')"
                        >
                            新增
                        </a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import UpDateModal from "./upDateModal";
import DetailDrawer from "./detailDrawer";
import { confirmModal, showMessage } from "@/utils/common";
import {
    apiMaterialCheckTeamList,
    apiMaterialCheckTeamEnable,
    apiMaterialCheckTeamDelete,
} from "@/api/qualityCheck";
export default defineComponent({
    components: {
        FilterBlock,
        UpDateModal,
        DetailDrawer,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "质检组编码",
                dataIndex: "code",
            },
            {
                title: "质检组名称",
                dataIndex: "name",
            },
            {
                title: "质检方案编码",
                dataIndex: "planCode",
            },
            {
                title: "质检方案名称",
                dataIndex: "planName",
            },
            {
                title: "绑定物料数量",
                dataIndex: "materialsCount",
            },
            {
                title: "启用状态",
                dataIndex: "enabled",
                key: "enabled",
                width: 100,
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 150,
            },
        ];

        const state = reactive({
            status: "",
            upDateId: null,
            detailId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiMaterialCheckTeamList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    status: state.status,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const handleOpenDetail = id => {
            state.detailId = id;
        };

        const changeSwitch = (enabled, id) => {
            const type = enabled ? "warning" : "info";
            const content = enabled
                ? "质检组禁用后，之后将无法在业务流程中使用该质检组信息，是否确认禁用？"
                : "质检组启用后，该质检组可以在业务流程中使用，是否确认启用？";
            confirmModal({
                type,
                content,
                onOk: async () => {
                    let res = await apiMaterialCheckTeamEnable(id, enabled);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const handleDelete = id => {
            confirmModal({
                type: "warning",
                content: "质检组删除后将无法恢复，是否确认删除该质检组？",
                onOk: async () => {
                    const {
                        pagination: { current },
                        data,
                    } = state;
                    let res = await apiMaterialCheckTeamDelete([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        if (current != 1 && data.length == 1) {
                            state.pagination.current--;
                        }
                        getTabelData();
                    }
                },
            });
        };

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            handleOpenDetail,
            handleDelete,
            getTabelData,
            changeSwitch,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
