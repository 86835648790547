<template>
    <CheckPlan
        v-model:planVisible="planVisible"
        type="INCOMING_MATERIAL_CHECK"
        @onSelect="onChangePlan"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}物料质检组`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="1000px"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-row :span="24" class="margin-bottom">
                <a-col :span="24">
                    <a-form-item
                        label="物料质检组编码"
                        name="code"
                        :rules="[
                            { required: true, message: '请输入物料质检组编码' },
                            { validator: checkAllSpace },
                        ]"
                        style="margin-bottom: 20px"
                    >
                        <a-form-item>
                            <div class="contant-item">
                                <a-input
                                    style="width: 82%; margin-right: 20px"
                                    :disabled="sysCreate || Boolean(upDateId)"
                                    v-model:value="formData.code"
                                    :maxlength="100"
                                    showCount
                                ></a-input>
                                <a-checkbox
                                    :disabled="Boolean(upDateId)"
                                    v-model:checked="sysCreate"
                                    v-if="!upDateId"
                                >
                                    系统生成
                                </a-checkbox>
                            </div>
                        </a-form-item>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-form-item
                label="物料质检组名称"
                name="name"
                :rules="[
                    { required: true, message: '请输入物料质检组名称' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="质检组方案选择"
                name="planName"
                :rules="[{ required: true, message: '请选择质检组方案' }]"
            >
                <a-form-item>
                    <SelectInput
                        :title="formData.planName"
                        :showDeleteIcon="false"
                        :onClick="() => (planVisible = true)"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <div style="margin-bottom: 16px; font-size: 16px; font-weight: bold">匹配物料列表</div>
            <MaterialList
                :list="formData.materialList"
                @onChange="data => (formData.materialList = data)"
            />

            <a-form-item :wrapper-col="{ offset: 19, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiMaterialCheckTeamUpdate } from "@/api/qualityCheck";
import Select from "@/components/Select";
import InspectionItemCategory from "@/components/CategoryModal";
import { showMessage, checkValueIsAvailable, getAutoCreateCode } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import UploadFile from "@/components/UploadFile";
import SelectInput from "@/components/SelectInput";
import MaterialList from "../materialList";
import CheckPlan from "@/components/CheckPlan";
import { apiMaterialCheckTeamDetail } from "@/api/qualityCheck";
export default defineComponent({
    props: ["upDateId"],
    emits: ["onUpdateSuccess"],
    components: {
        Select,
        InspectionItemCategory,
        DownOutlined,
        UploadFile,
        SelectInput,
        Select,
        MaterialList,
        CheckPlan,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            planVisible: false,
            sysCreate: false,
            formRef: null,
            autoCreateCode: "",
            formData: {
                id: "",
                name: "",
                code: "",
                planName: "",
                qcPlanCode: "",
                materialList: [],
            },
        });

        const handleCancel = () => {
            state.formRef.resetFields();
            state.visible = false;
            state.formData.id = "";
            state.sysCreate = false;
            state.autoCreateCode = "";
            state.formData.materialList = [];
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            const reqData = {
                ...state.formData,
                erpMaterialIds: state.formData.materialList.map(item => item.id),
            };
            if (reqData?.erpMaterialIds.length == 0) {
                return showMessage("info", "请添加物料");
            }
            let res = await apiMaterialCheckTeamUpdate(reqData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("onUpdateSuccess");
            }
        };

        const getIndexData = async id => {
            let res = await apiMaterialCheckTeamDetail(id);
            if (res.status === "SUCCESS") {
                const { id, code, name, basQcPlan, materials } = res?.data;
                state.formData.id = id;
                state.formData.code = code;
                state.formData.name = name;
                state.formData.planName = basQcPlan?.name;
                state.formData.qcPlanCode = basQcPlan?.code;
                state.formData.materialList = materials.map((item, index) => ({ ...item, index }));
            }
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onChangePlan = arr => {
            const [value] = arr || [];
            state.formData.planName = value ? value?.name : "";
            state.formData.qcPlanCode = value ? value?.code : "";
        };

        const getOrder = async () => {
            let code = await getAutoCreateCode("BAS_QC_ITEM");
            state.autoCreateCode = code;
            state.formData.code = code;
        };

        watch(
            () => state.sysCreate,
            newVal => {
                if (newVal && !state.autoCreateCode) {
                    getOrder();
                } else {
                    state.formData.code = state.autoCreateCode;
                }
                if (!newVal) state.formData.code = "";
            }
        );

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    newVal && getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onChangePlan,
        };
    },
});
</script>
